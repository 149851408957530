<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style='text-align:right'>
        <el-button @click='$router.go(-1)' type='primary'>返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="activeName2" @tab-click="handleClick">
        <el-tab-pane v-if='authority.tabs["parameters"]' label="业务参数配置" name="first">
           <business-manage :authority='authority.tabs["parameters"]' :details='details' ref='business'></business-manage>
        </el-tab-pane>
        <el-tab-pane v-if='authority.tabs["channel"]' label="通道管理" name="second">
           <channe-manage :authority="authority.tabs['channel']" :details='details' ref="channe"></channe-manage>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['calculate']" label="计费规则" name="third">
          <charge-rule :authority="authority.tabs['calculate']" :details='details' ref="charge"></charge-rule>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import businessManage from "./parkSetChilds/BusinessManage/thirdConfig"
import channeManage from "./parkSetChilds/ChanneManage";
import chargeRule from "./parkSetChilds/chargeRule";
export default {
  name: 'parkConfig',
  data() {
    return {
      authority: this.$setAuthority('parkConfig') || this.$setAuthority('secondParkConfig'),
      activeName2: 'first',
      details: this.$route.query
    }
  },
  watch: {
    authority: {
      handler(ne, ol) {
	    let key = Object.keys(ne.tabs)[0];
        this.$nextTick(() => {
		   switch (key) {
				case "parameters":
					this.activeName2 = "first";
					this.$refs.business.init();
					break;
				case "channel":
					this.activeName2 = "second";
					this.$refs.channe.init();
					break;
				case "calculate":
					this.activeName2 = "third";
					this.$refs.charge.init();
					break;
				default:
		   }
        })
      },
      immediate: true
    }
  },
  methods: {
    handleClick(tab, event) {
      switch (tab.name) {
        case 'first':
          this.$refs.business.init();
          break;
        case 'second':
          this.$refs.channe.init();
          break;
        case 'third':
          this.$refs.charge.init();
          break;
        default:
      }
    }
  },
  components: {
	businessManage,
	channeManage,
	chargeRule,
  },
  created() {
  },
  mounted() {
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
  margin 0 0 12px 0
.content

  overflow hidden
  .searchWrapper
    overflow hidden
    background #EFF2F7
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    float right
    .export
      font-size 12px
      .iconfont
        margin-right 0
  .btnWrapper
    text-align left
    margin-top 28px
    float left
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.transfer
  margin 0 auto
.button
  margin-bottom 15px
  text-align right
.ruleDetail
  width 500px
  height 150px
  overflow auto
  border 1px solid #bfcbd9
  border-radius 5px
  color #1f2d3d
.feeDis
  margin-left 20px
.berth-sort
  margin-top 10px
</style>
<style>
.el-table .success-row {
  background: #f0f9eb !important;
}
</style>
