<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content" v-loading="loading">
      <h2 class="title">
        <div class="title_icon"></div>出入场配置
      </h2>
      <el-form ref="form" :rules="rules" :model="form" label-width="200px" style="padding:0">
        <el-row type="flex" justify="center">
          <el-col :span="10">
            <!-- <el-form-item label="无牌车是否允许入场" prop="noPlateEntry">
              <el-radio-group v-model="form.noPlateEntry">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="车位已满时" prop="berthFullRule" v-if="!parkingSystemType">
              <el-select v-model="form.berthFullRule" placeholder="请选择">
                <el-option value="" label="请选择">请选择</el-option>
                <el-option :label="value.value" :value="value.id" :key="value.id"
                  v-for="value in parkFullList"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车位满时是否允许月租车入场" prop="monthlyCarRentalEntry" v-if="parkingSystemType">
              <el-radio-group v-model="form.monthlyCarRentalEntry">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item label="仅限会员车入场" prop="memberOnly" v-if="!parkingSystemType">
              <el-radio-group v-model="form.memberOnly">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item label="单出场异常放行" prop="errorExitPass" v-if="!parkingSystemType">
              <el-radio-group v-model="form.errorExitPass">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
             <!-- <el-form-item label="场内缴费后免费离场时间" prop="innerPayFreeLeaveTime" v-if="parkingSystemType">
              <el-input v-model="form.innerPayFreeLeaveTime" style="width:120px;margin:0 5px 0 0"
                @keyup.native="timeNumber"></el-input>分钟
            </el-form-item> -->
            <!-- <el-form-item label="入场时车辆先放行客服后处理" prop="inFirstServiceNext" v-if="parkingSystemType && remote">
              <el-radio-group v-model="form.inFirstServiceNext">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="场内缴费后免费离场时间" prop="innerPayFreeLeaveTime">
              <el-input v-model="form.innerPayFreeLeaveTime" style="width:120px;margin:0 5px 0 0"
                @keyup.native="timeNumber"></el-input>分钟
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <!-- <el-form-item label="支持相似车牌出入场匹配" prop="similarPlateMatch">
              <el-radio-group v-model="form.similarPlateMatch">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="军警车是否计费" prop="policeCarFree">
              <el-radio-group v-model="form.policeCarFree">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="断网时是否允许车辆欠费离场" prop="noNetworkDeparture" v-if="parkingSystemType != 0">
              <el-radio-group v-model="form.noNetworkDeparture">
                <el-radio :label="2">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item label="场内缴费后免费离场时间" prop="innerPayFreeLeaveTime"  v-if="!parkingSystemType">
              <el-input v-model="form.innerPayFreeLeaveTime" style="width:120px;margin:0 5px 0 0"
                @keyup.native="timeNumber"></el-input>分钟
            </el-form-item> -->
            <!-- <el-form-item label="记录缺失收费区域偏向" prop="childParkId"  v-if="parkingSystemType">
              <el-select v-model="form.childParkId" placeholder="请选择">
                <el-option :label="value.parkName" :value="value.parkId" :key="value.parkId"
                  v-for="value in parkList"></el-option>
              </el-select>
            </el-form-item> -->
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :span="14">
            <el-form-item>
			   <!--v-if="syncStatus == 2 && authority.button.status"-->

              <el-button type="danger" plain @click="updateData()" :loading="updateLoading"
                v-if="syncStatus==2 && authority.button.status && parkingSystemType != 0">同步失败</el-button>
              <el-button type="primary" plain @click="updateData()" :loading="updateLoading"
                v-if="syncStatus==1 && authority.button.status && parkingSystemType != 0">同步成功</el-button>
              <el-button type="primary" @click="save" :loading="saveLoading"
                v-if="authority.button.addSync" style="margin:0 0 0 25px">{{parkingSystemType != 0 ? '保存并同步' : '保存'}}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>


<script>
  import {
    dateFormat
  } from "../../../../../common/js/public.js";
  export default {
    name: "businessIndex",
    props: {
      authority: {
        type: Object,
        default: function () {
          return {};
        },
      },
      details: {
        type: Object,
        default: function () {
          return {};
        },
      }
    },
    data() {
      return {
        index: 0,
        total: 0,
        loading: false,
        form: {
          parkBusiParamId: "",
          noPlateEntry: "",
          berthFullRule: '',
          monthlyCarRentalEntry: "",
          // memberOnly: "",
          innerPayFreeLeaveTime: "",
          errorExitPass: '',
          similarPlateMatch: "",
          policeCarFree: "",
          exitAbnormalPass: "",
          childParkId: "",
          innerPayable: "",
          unattendedState: "",
          // inFirstServiceNext: ""
          noNetworkDeparture: ""
        },
        rules: {
          noPlateEntry: [{
            required: true,
            message: "请选择否允许入场",
            trigger: "change"
          }],
          // inFirstServiceNext: [
          //   { required: true, message: "请选择入场时处理", trigger: "change" },
          // ],
          berthFullRule: [{
            required: true,
            message: "请选择车位已满时",
            trigger: "change"
          }],
          monthlyCarRentalEntry: [
            { required: true, message: "请选择月租车入场", trigger: "change" },
          ],
          // memberOnly: [
          //   { required: true, message: "请选择是否只限会员车入场", trigger: "change" },
          // ],
          innerPayFreeLeaveTime: [{
            required: true,
            message: "请输入免费离场时间",
            trigger: "blur"
          }],
          similarPlateMatch: [{
            required: true,
            message: "请选择车牌出入场匹配",
            trigger: "change",
          }],
          policeCarFree: [{
            required: true,
            message: "请选择军警车是否计费",
            trigger: "change",
          }],
          noNetworkDeparture: [{
            required: true,
            message: "请选择断网时是否允许车辆欠费离场",
            trigger: "change",
          }],
          exitAbnormalPass: [{
            required: true,
            message: "请选择单出场异常放行",
            trigger: "change",
          }],
          childParkId: [{
            required: true,
            message: "请选择缺失收费区域偏向",
            trigger: "change",
          }]
        },
        // 记录缺失数据
        parkList: [],
        // 车位已满时
        parkFullList: [{
          id: 1,
          value: '不允许任何车辆入场'
        }, {
          id: 2,
          value: '只允许月租车入场'
        }, {
          id: 3,
          value: '允许任何车辆出入场'
        }],
        updateLoading: false,
        saveLoading: false,
        syncStatus: "",
        // remote: "",
        parkingSystemType: "",
      };
    },
    methods: {
      // 保存
      save() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            // console.log('提交分析:' + JSON.stringify(this.form))
            this.saveLoading = true;
            this.$axios
              .post("/acb/2.0/bacb/parkBusiParam/save", {
                data: {
                  parkId: this.$route.query.parkId,
                  ...this.form,
                },
              })
              .then((res) => {
                this.saveLoading = false;
                if (res.state == 0) {
                  // if (res.value) {
                  this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  }).then(() => {
                    this.searchData();
                  });
                  //  }
                } else {
                  this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  });
                }
              })
              .catch((error) => {
                this.saveLoading = false;
              });
          } else {
            return false;
          }
        });
      },

      // 详情
      searchData() {
        for (let i in this.form) {
          this.form[i] = "";
        }
        let parkId = this.$route.query.parkId;
        let url = "/acb/2.0/bacb/parkBusiParam/getByParkId/" + parkId;
        this.loading = true;
        this.$axios
          .get(url)
          .then((res) => {
            this.loading = false;
            if (res.state == 0) {
              if (JSON.stringify(res.value) !== "{}") {
                // console.log("A1");
                this.form.parkBusiParamId = res.value.parkBusiParamId;
                this.form.noPlateEntry = res.value.noPlateEntry;
                this.form.similarPlateMatch = res.value.similarPlateMatch;
                this.form.monthlyCarRentalEntry = res.value.monthlyCarRentalEntry;
                // this.form.memberOnly = res.value.memberOnly;
                this.form.policeCarFree = res.value.policeCarFree;
                this.form.berthFullRule = res.value.berthFullRule;
                this.form.errorExitPass = res.value.errorExitPass;
                this.form.exitAbnormalPass = res.value.exitAbnormalPass;
                this.form.innerPayFreeLeaveTime = res.value.innerPayFreeLeaveTime;
                this.form.childParkId = res.value.childParkId;
                this.form.innerPayable = res.value.innerPayable;
                this.form.unattendedState = res.value.unattendedState;
                // this.form.inFirstServiceNext = res.value.inFirstServiceNext;
                this.syncStatus = res.value.syncStatus;
                this.parkingSystemType = res.value.parkSystemType;
                this.form.noNetworkDeparture  =  res.value.noNetworkDeparture;
                // this.remote = res.value.remote;
              } else {
                // console.log("A2");
                this.$refs.form.resetFields();
              }
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      },

      // 查询车场列表
      getParkList() {
        this.carGroupList = [];
        let url = "/acb/2.0/bacb/park/children/" + this.$route.query.parkId;
        this.$axios
          .get(url, {
            data: {
              // parkId: this.$route.query.parkId,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.parkList = res.value || [];
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      },

      // 同步失败
      updateData() {
        this.updateLoading = true;
        this.$axios
          .post("/acb/2.0/bacb/parkBusiParam/sync", {
            data: {
              parkId: this.$route.query.parkId,
              parkBusiParamId: this.form.parkBusiParamId,
            },
          })
          .then((res) => {
            this.updateLoading = false;
            if (res.state == 0) {
              this.$alert(res.value, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              }).then(() => {
                this.searchData();
              });
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          })
          .catch((error) => {
            this.updateLoading = false;
          });
      },

      timeNumber(event) {
        let value = event.target.value;
        value = value.replace(/[^\d]/g, "");
        value = value.replace(/^0/g, "");
        if (value > 60) {
          value = 60;
        }
        this.form.innerPayFreeLeaveTime = value;
      },

      // 初始化
      init() {
        this.getParkList();
        this.searchData();
      },
    },
    beforeDestroy() {
      // sessionStorage.searchData = JSON.stringify(this.formInline);
      // sessionStorage.searchFlag = 1;
    },
    components: {},
    created() {
      this.searchData();
      // console.log('业务参数分析:' + JSON.stringify(this.authority))
    },
    computed: {},
  };

</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
  .breadcrumb {
    height: 35px;
  }

  .content {
    background: #FFFFFF;
    overflow: hidden;

    .pagerWrapper {
      text-align: right;
      margin-top: 28px;
      font-size: 12px;

      .export {
        font-size: 12px;

        .iconfont {
          margin-right: 0;
        }
      }
    }
  }

  .detail {
    width: 61.4%;
    height: 82.9%;
    position: absolute;
    top: 8.6%;
    left: 20.1%;
    background: #FFFFFF;
    box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
    border-radius: 3px;
    z-index: 1000;
    box-sizing: border-box;
    padding: 20px 0;

    .title {
      position: absolute;
      background: #3F4A56;
      border-radius: 3px 3px 0 0;
      color: #fff;
      width: 100%;
      top: 0;
      left: 0;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      padding: 0 12px;
      box-sizing: inherit;
      z-index: 100;

      .el-icon-close {
        float: right;
        margin-top: 14px;
      }
    }

    .info {
      margin-top: 20px;
      padding: 2.1%;
      height: 100%;
      overflow: auto;
      box-sizing: inherit;
    }
  }

  .mask {
    background: rgba(49, 53, 65, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }

  .el-dropdown-link {
    cursor: pointer;
  }

</style>
